import React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../components/Layout'
import { BlogPostQuery } from '../../types/graphql-types'
import { BlogPageContext } from '../../.gatsby/gatsby-node'
import BlogPost from '../components/BlogPost'

const previousData = ({ previous, previousOld }: BlogPostQuery) => {
  if (previous) return { id: previous.blogsId, title: previous.title }
  else if (previousOld)
    return { id: previousOld.id, title: previousOld.title?.rendered }
  else return undefined
}

const OldBlogPostTemplate: React.FC<PageProps<
  BlogPostQuery,
  BlogPageContext
>> = ({ data, location }) => {
  const { post, previous, next, previousOld } = data

  return (
    <Layout path="Blog" title={post?.title}>
      <BlogPost
        title={post?.title}
        date={post?.date}
        body={post?.body}
        previous={previousData({ previous, previousOld })}
        next={next ? { id: next.blogsId, title: next.title } : undefined}
        url={location.href}
      />
    </Layout>
  )
}

export default OldBlogPostTemplate

export const pageQuery = graphql`
  query BlogPost(
    $slug: String!
    $previous: String
    $next: String
    $previousOld: String
  ) {
    post: microcmsBlogs(blogsId: { eq: $slug }) {
      categories {
        name
        id
      }
      body
      date
      blogsId
      title
    }
    previous: microcmsBlogs(blogsId: { eq: $previous }) {
      blogsId
      title
    }
    next: microcmsBlogs(blogsId: { eq: $next }) {
      blogsId
      title
    }
    previousOld: wordpressJson(id: { eq: $previousOld }) {
      id
      title {
        rendered
      }
    }
  }
`
